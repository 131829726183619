import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Navbar from 'src/components/Navbar/Navbar'
import CookiesPopup from 'src/components/CookiesPopup/CookiesPopup'
import useTranslation from 'src/hooks/useTranslation'
import './Layout.scss'

const Layout = ({ children, location, pageContext }) => {
  const isKa = location.pathname.startsWith('/ka')
  const lang = isKa ? 'ka' : 'en'
  useEffect(() => {
    if (window.Stack) {
      document.body.classList.add('inside-stack')
    }
    if (window) {
      document.body.className = `script-${lang}`
    }
  }, [])

  useEffect(() => {
    import('src/utils/helpers').then(({ getWindow, parseQueryString }) => {
      const window = getWindow()
      const query = window.location.search.substr(1)
      const { ref, utm_source, utm_campaign } = parseQueryString(query)

      if (ref) {
        window.sessionStorage.setItem('ref', ref)
      }
      if (utm_source || utm_campaign) {
        window.sessionStorage.setItem('utm_source', utm_source)
        window.sessionStorage.setItem('utm_campaign', utm_campaign)
      }
    })
  })

  if (pageContext.layout === 'empty')
    return (
      <div>
        <Helmet>
          <style type="text/css">{`
          body {
            font-size: inherit;
            font-family: inherit;
            color: inherit;
          }
        `}</style>
        </Helmet>
        {children}
      </div>
    )

  return (
    <>
      <Helmet>
        <script src="https://unpkg.com/ionicons@5.1.2/dist/ionicons.js"></script>
      </Helmet>

      <main lang={lang}>
        <div className="navigation">
          <Navbar lang={lang} />
        </div>
        {children}
        <CookiesPopup lang={lang} />
      </main>
    </>
  )
}

export default Layout
