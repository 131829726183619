/* eslint jsx-a11y/accessible-emoji: 0 */

import React from 'react'
import Logo from 'src/assets/logo.svg'
import HeartIcon from 'src/assets/icons/heart.svg'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import useTranslation from 'src/hooks/useTranslation'
import './NavBar.scss'

const NavBar = ({ location }) => {
  const { t } = useTranslation({ location })

  const isKa = location.pathname.startsWith('/ka')
  const prefix = isKa ? '/ka' : ''
  const isHomeScreen = [
    '/',
    '/en',
    '/ka',
    '/en/',
    '/ka/',
    '/mobile',
    '/ka/mobile',
  ].includes(location.pathname)

  return (
    <nav className={`nav ${isHomeScreen && 'nav--dark'}`}>
      <div className="container container--lg p-0">
        <div className="d-flex align-items-center">
          <a href={`https://stackbrowser.com`}>
            <div className="nav__logo d-flex align-items-center">
              <img alt="Logo" src={Logo} className="nav__logo-img" />
              <span className="nav__logo-text ml-2 font-weight-bold">
                Stack
                <span className="nav__logo-text--hidden">
                  - One Screen, All Apps
                </span>
              </span>
            </div>
          </a>

          <div className="ml-auto d-flex">
            <Link
              activeClassName="nav-link-item--active"
              to="https://stackbrowser.com/join-us"
              className="mr-2 nav-link-item"
            >
              Jobs
            </Link>
            <Link
              activeClassName="nav-link-item--active"
              to="https://stackbrowser.com/features"
              className="mr-2 nav-link-item d-none d-lg-inline-block"
            >
              Features
            </Link>
            <Link
              activeClassName="nav-link-item--active"
              to="https://stackbrowser.com/product-updates"
              className="mr-2 nav-link-item d-none d-lg-inline-block"
            >
              Changelog
            </Link>
            <Link
              activeClassName="nav-link-item--active"
              to="https://stackbrowser.com/story"
              className="mr-2 nav-link-item d-none d-lg-inline-block"
            >
              The Story
            </Link>
            <Link
              activeClassName="nav-link-item--active"
              to="https://stackbrowser.com/blog"
              className="mr-2 nav-link-item"
            >
              Blog
            </Link>
            <Link
              activeClassName="nav-link-item--active"
              to={`${prefix}/supporters`}
              className="mr-2 nav-link-item"
            >
              Stackers
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}
export default () => (
  <Location>{(locationProps) => <NavBar {...locationProps} />}</Location>
)
