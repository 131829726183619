import en from "src/locales/en.json";
import ka from "src/locales/ka.json";

const locales = { en, ka }

function getDescendantProp(obj, desc) {
  var arr = desc.split(".");
  while(arr.length && (obj = obj[arr.shift()]));
  return obj;
}


const useTranslation = ({ location }) => {
  const isKa = location.pathname.startsWith('/ka')
  const lang = isKa ? 'ka' : 'en'

  const t = (key) => {
    return getDescendantProp(locales[lang], key)
  }

  return { t, lang }
}

export default useTranslation