// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-en-js": () => import("./../../../src/pages/download.en.js" /* webpackChunkName: "component---src-pages-download-en-js" */),
  "component---src-pages-download-ka-js": () => import("./../../../src/pages/download.ka.js" /* webpackChunkName: "component---src-pages-download-ka-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ka-js": () => import("./../../../src/pages/index.ka.js" /* webpackChunkName: "component---src-pages-index-ka-js" */),
  "component---src-pages-mobile-en-js": () => import("./../../../src/pages/mobile.en.js" /* webpackChunkName: "component---src-pages-mobile-en-js" */),
  "component---src-pages-mobile-ka-js": () => import("./../../../src/pages/mobile.ka.js" /* webpackChunkName: "component---src-pages-mobile-ka-js" */),
  "component---src-pages-nft-js": () => import("./../../../src/pages/nft.js" /* webpackChunkName: "component---src-pages-nft-js" */),
  "component---src-pages-pricing-en-js": () => import("./../../../src/pages/pricing.en.js" /* webpackChunkName: "component---src-pages-pricing-en-js" */),
  "component---src-pages-pricing-ka-js": () => import("./../../../src/pages/pricing.ka.js" /* webpackChunkName: "component---src-pages-pricing-ka-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-success-en-js": () => import("./../../../src/pages/success.en.js" /* webpackChunkName: "component---src-pages-success-en-js" */),
  "component---src-pages-success-ka-js": () => import("./../../../src/pages/success.ka.js" /* webpackChunkName: "component---src-pages-success-ka-js" */),
  "component---src-pages-supporters-en-js": () => import("./../../../src/pages/supporters.en.js" /* webpackChunkName: "component---src-pages-supporters-en-js" */),
  "component---src-pages-supporters-ka-js": () => import("./../../../src/pages/supporters.ka.js" /* webpackChunkName: "component---src-pages-supporters-ka-js" */)
}

