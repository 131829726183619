import React, { useState, useEffect } from "react"
import "./CookiesPopup.scss"
import { Link } from "gatsby"
import CloseIcon from "src/assets/icons/close.svg"

const CookiesPopup = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false)

  useEffect(() => {
    import('src/utils/helpers').then(({ getWindow }) => {
      setCookiesAccepted(getWindow().localStorage.getItem('cookies_accepted'))
    })
    
  }, [])

  const acceptCookies = () => {
    localStorage.setItem('cookies_accepted', true)
    setCookiesAccepted(true)
  }

  if (cookiesAccepted || cookiesAccepted === false) {
    return (null)
  }

  return (
    <div className="cookies-popup" onClick={acceptCookies}>
      By using this website, you agree to our <a href="/privacy-policy"> cookie policy </a>

      <button className="cookies-popup__button">
        <img src={CloseIcon} alt=""/>
      </button>
    </div>
  )
}

export default CookiesPopup
